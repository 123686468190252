<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-3">
            <OrganizationNav />
          </div>
          <div class="col-12 col-md-9">
            <h5>
              Products
              <span v-if="products.total !== 0" class="text-muted ml-4"
                >{{ products.total }} products</span
              >
            </h5>
            <div class="card border-0 shadow-sm mb-4">
              <div
                v-if="Object.keys(products.data).length !== 0"
                class="card-body"
              >
                <data-table
                  :headers="headers()"
                  sort-url="/order"
                  :data="products.data"
                >
                  <template #image="{ item }">
                    <img :src="getSrc(item)" width="100px" height="100px" />
                  </template>
                  <template #sku="{ item }">
                    <strong>{{ item.sku }}</strong>
                  </template>
                  <template #name="{ item }">
                    <div class="h4" :variant="item.name">
                      {{ item.name }}
                    </div>
                  </template>
                  <template #price="{ item }">
                    <money-format
                      :value="parseInt(item.regular_price)"
                      :locale="'en'"
                      :currency-code="'DKK'"
                    />
                  </template>
                  <template #stock_status="{ item }">
                    <status-badge :variant="item.stock_status">
                      {{ item.stock_status }}
                    </status-badge>
                  </template>
                </data-table>
                <pagination
                  :data="products"
                  :limit="3"
                  :show-disabled="true"
                  align="right"
                  @pagination-change-page="getProducts"
                >
                  <span slot="prev-nav" class="text-capitalize"
                    ><i class="fas fa-chevron-left mr-2" /> Prev</span
                  >
                  <span slot="next-nav" class="text-capitalize"
                    >Next<i class="fas fa-chevron-right ml-2"
                  /></span>
                </pagination>
              </div>
              <div v-else class="card-body">
                <p class="text-muted">
                  This order list is empty: You're seeing this message either
                  because: 1. There are no relevant orders to show for the
                  filter 2. The plugin/api is not correctly connected 3.
                  Automation.app is (unlikely) down. To resolve this, try new
                  filtering options or contact
                  <a href="mailto:nerds@automation.app">nerds@automation.app</a
                  >.
                </p>
                <div class="p-4 rounded border-zip text-center">
                  <p class="mb-0">
                    No recent orders has been found!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import MoneyFormat from "vue-money-format";
import jsonAPI from "@/api";
import DataTable from "@/components/DataTable/DataTable.vue";
import Default from "@/layouts/default.vue";
import OrganizationNav from "@/components/Nav/OrganizationNav.vue";
import StatusBadge from "@/components/UI/StatusBadge.vue";

export default {
  name: "ProductList",
  components: {
    DataTable,
    Default,
    OrganizationNav,
    StatusBadge,
    MoneyFormat,
  },
  beforeRouteEnter(to, from, next) {
    let uri = "/product?page=1";

    if (to.query.sort && to.query.dir) {
      uri += "&sort=" + to.query.sort + "&dir=" + to.query.dir;
    }

    jsonAPI.get(uri).then((response) => {
      next((vm) => {
        vm.products.data = response.data;
      });
    });
  },
  data() {
    return {
      layout: "Default",
      products: {
        data: [],
        total: 0,
        links: {},
      },
    };
  },
  methods: {
    headers() {
      return [
        { title: "Image", key: "image", sortable: true, sortkey: "image" },
        { title: "SKU", key: "sku", sortable: true, sortkey: "sku" },
        { title: "Name", key: "name", sortable: true, sortkey: "name" },
        { title: "Price", key: "price", sortable: true, sortkey: "price" },
        {
          title: "Stock",
          key: "stock_status",
          sortable: true,
          sortkey: "stock_status",
        },
      ];
    },
    getSrc(item) {
      if (item.images && item.images[0]) {
        return item.images[0].src;
      } else {
        return item.src;
      }
      //return "";
    },
    getProducts: function(page = 1) {
      let uri = "/product?page=" + page;

      if (this.$route.query.sort && this.$route.query.dir) {
        uri +=
          "&sort=" + this.$route.query.sort + "&dir=" + this.$route.query.dir;
      }

      jsonAPI
        .get(uri)
        .then((response) => {
          this.products = response.data;
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Error fetching results",
            type: "error",
          });
        });
    },
  },
};
</script>
