var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"component"},[_c('div',{staticClass:"row fadeInUp",attrs:{"role":"main"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-3"},[_c('OrganizationNav')],1),_c('div',{staticClass:"col-12 col-md-9"},[_c('h5',[_vm._v(" Products "),(_vm.products.total !== 0)?_c('span',{staticClass:"text-muted ml-4"},[_vm._v(_vm._s(_vm.products.total)+" products")]):_vm._e()]),_c('div',{staticClass:"card border-0 shadow-sm mb-4"},[(Object.keys(_vm.products.data).length !== 0)?_c('div',{staticClass:"card-body"},[_c('data-table',{attrs:{"headers":_vm.headers(),"sort-url":"/order","data":_vm.products.data},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":_vm.getSrc(item),"width":"100px","height":"100px"}})]}},{key:"sku",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.sku))])]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"h4",attrs:{"variant":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_c('money-format',{attrs:{"value":parseInt(item.regular_price),"locale":'en',"currency-code":'DKK'}})]}},{key:"stock_status",fn:function(ref){
var item = ref.item;
return [_c('status-badge',{attrs:{"variant":item.stock_status}},[_vm._v(" "+_vm._s(item.stock_status)+" ")])]}}],null,false,1934825588)}),_c('pagination',{attrs:{"data":_vm.products,"limit":3,"show-disabled":true,"align":"right"},on:{"pagination-change-page":_vm.getProducts}},[_c('span',{staticClass:"text-capitalize",attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_c('i',{staticClass:"fas fa-chevron-left mr-2"}),_vm._v(" Prev")]),_c('span',{staticClass:"text-capitalize",attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v("Next"),_c('i',{staticClass:"fas fa-chevron-right ml-2"})])])],1):_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted"},[_vm._v(" This order list is empty: You're seeing this message either because: 1. There are no relevant orders to show for the filter 2. The plugin/api is not correctly connected 3. Automation.app is (unlikely) down. To resolve this, try new filtering options or contact "),_c('a',{attrs:{"href":"mailto:nerds@automation.app"}},[_vm._v("nerds@automation.app")]),_vm._v(". ")]),_c('div',{staticClass:"p-4 rounded border-zip text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" No recent orders has been found! ")])])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }